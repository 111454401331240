import { Injectable, OnDestroy } from '@angular/core';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

type TranslationQueryResult = {
  'shared.material.paginator.itemPerPage': string;
  'shared.material.paginator.nextPage': string;
  'shared.material.paginator.previousPage': string;
  'shared.material.paginator.ofLabel': string;
};

@Injectable()
export class CustomMatPaginatorIntl
  extends MatPaginatorIntl
  implements OnDestroy
{
  unsubscribe: Subject<void> = new Subject<void>();
  OF_LABEL = 'of';

  constructor(private translate: TranslateService) {
    super();

    this.translate.onLangChange
      .pipe(takeUntil(this.unsubscribe))
      .subscribe(() => {
        this.getAndInitTranslations();
      });

    this.getAndInitTranslations();
  }

  ngOnDestroy() {
    this.unsubscribe.next();
    this.unsubscribe.complete();
  }

  getAndInitTranslations() {
    this.translate
      .get([
        'shared.material.paginator.itemPerPage',
        'shared.material.paginator.nextPage',
        'shared.material.paginator.previousPage',
        'shared.material.paginator.ofLabel',
      ])
      .pipe(takeUntil(this.unsubscribe))
      .subscribe((translation: TranslationQueryResult) => {
        this.itemsPerPageLabel =
          translation['shared.material.paginator.itemPerPage'];
        this.nextPageLabel = translation['shared.material.paginator.nextPage'];
        this.previousPageLabel =
          translation['shared.material.paginator.previousPage'];
        this.OF_LABEL = translation['shared.material.paginator.ofLabel'];
        this.changes.next();
      });
  }

  override getRangeLabel = (page: number, pageSize: number, length: number) => {
    if (length === 0 || pageSize === 0) {
      return `0 ${this.OF_LABEL} ${length}`;
    }
    length = Math.max(length, 0);
    const startIndex = page * pageSize;
    const endIndex =
      startIndex < length
        ? Math.min(startIndex + pageSize, length)
        : startIndex + pageSize;
    return `${startIndex + 1} - ${endIndex} ${this.OF_LABEL} ${length}`;
  };
}
